
import {
  EncryptedUser,
  FeatureFlag,
  Office,
  OfficeType,
  Role,
  TrackingEvent,
  UserStatus,
} from '@caresend/types';
import {
  Loading,
  NavBar,
  NavItem,
  NavigatorAuthAction,
  NavigatorRouteOption,
  Portal,
  flagOn,
  getPortalChildRoutes,
  getRoute,
  getRouter,
  getStore,
  isProd,
  portalJobsRoute,
  useNavBarHeight,
  visitsPageRoute,
} from '@caresend/ui-components';
import { hasStatus, isNullish } from '@caresend/utils';
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from 'vue';

import { PartnerName, partnerInfo } from '@/data/partner';
import { showIntercomMessenger } from '@/database/intercom/methods';
import { trackEvent } from '@/functions/tracking/tracking';
import GeoEnforcer from '@/GeoEnforcer.vue';
import {
  loginRoute,
  manageTeamRoute,
  officeOrdersRoute,
  officeSettingsRoute,
  orderKitRoute,
  profileRoute,
  reportsRoute,
  userSettingsRoute,
  walletRoute,
  workSettingsRoute,
} from '@/router/locations';
import { routeNames } from '@/router/model';

export default defineComponent({
  name: 'App',
  components: {
    GeoEnforcer,
    Loading,
    NavBar,
    Portal,
  },
  setup() {
    const store = getStore();
    const route = getRoute();
    const router = getRouter();

    const user = computed<EncryptedUser | undefined>(() => store.state.auth.user);
    watch(
      [
        () => user.value,
        () => route.value.meta?.authRequired,
      ],
      () => {
        if (
          route.value.meta?.authRequired
          && !user.value
        ) {
          router.push(loginRoute());
        }
      },
      {
        deep: true,
        immediate: true,
      },
    );

    const isNurse = computed<boolean>(() =>
      store.state.auth.user?.role === Role.NURSE,
    );
    const isUserApproved = computed<boolean>(() =>
      hasStatus(store.state.auth.user, UserStatus.APPROVED),
    );
    const isOfficeAdmin = computed<boolean>(() =>
      store.state.auth.user?.role === Role.ASSISTANT
      || store.state.auth.user?.role === Role.PRESCRIBER,
    );

    const geoRoles: Role[] = [Role.NURSE, Role.PACKER];
    const contentElement = ref<HTMLElement | null>(null);

    /** If true, we will not show the portal and instead use the router-view */
    const disablePortal = computed<boolean>(() =>
      route.value.meta?.noPortal ?? false,
    );

    const navBarVisible = computed<boolean>(() =>
      store.state.app.navBarVisible
      && !route.value.meta?.minimizableNavBar
      && (!isNurse.value || disablePortal.value),
    );

    const navBarClassNames = computed<string[]>(() => {
      const classNames: string[] = [];

      if (!navBarVisible.value) {
        classNames.push('App__nav-bar--hidden');
      }

      return classNames;
    });

    onMounted(() => {
      const { syncContentMarginWithNavBarHeight } = useNavBarHeight();
      syncContentMarginWithNavBarHeight(contentElement, { isVisibleOverride: navBarVisible });
    });

    onBeforeMount(() => {
      if (isProd) return;

      const { query } = route.value;
      const lat = !isNullish(query.lat) ? Number(query.lat) : undefined;
      const lng = !isNullish(query.lng) ? Number(query.lng) : undefined;

      if (isNullish(lat) || isNullish(lng)) {
        store.commit('app/SET_QA_OVERRIDES', null);
        return;
      }

      store.commit('app/SET_QA_OVERRIDES', { location: { lat, lng } });
    });

    const isWhiteBackground = computed<boolean>(() => {
      const whiteBgMeta = route.value.meta?.whiteBackground;
      const isWhiteBg = typeof whiteBgMeta === 'function'
        ? whiteBgMeta({ store }).value
        : !!whiteBgMeta;

      return isWhiteBg;
    });

    // Set the custom background color on the <html> element to prevent it from
    // not filling the background on long screens due to fixed `height: 100%`
    // on this component.
    //
    // TODO: Setting classes on the <html> element is not a conventional in Vue.
    // If we can refactor the app to support setting a class in a conventional
    // way (e.g. via a computed property set as `:class` on an element), we
    // should do that. Changing fixed `height: 100%` would require a deep
    // investigation of our layouts on all screens on mobile and desktop.
    // Currently some layouts rely on `height: 100%` to place the footer at the
    // bottom of the layout on screens with minimal content.
    watch(
      () => isWhiteBackground.value,
      (isWhite) => {
        if (isWhite) {
          document.documentElement.classList.add('white-bg');
        } else {
          document.documentElement.classList.remove('white-bg');
        }
      },
      { immediate: true },
    );

    /**
     * Loading conditions that prevent the router-view from rendering.
     */
    const isLoadingBlocking = computed(() =>
      route.value.name !== routeNames.DEV_ADVANCED_SETTINGS
      && !store.state.variables.variables,
    );

    /**
     * Loading conditions that allow the router-view to render.
     */
    const isLoadingNonBlocking = computed(() =>
      store.state.auth.authLoading
      || store.state.app.showRouteLoading,
    );

    // TODO: Use similar logic as patient app for dynamic partner names if
    // partner/whitelabeling support is reintroduced.
    const partnerName = computed<PartnerName | undefined>(() =>
      route.value.params?.partnerName as PartnerName,
    );
    const partnerLogoUrl = computed<string | undefined>(() =>
      partnerName.value
        ? partnerInfo[partnerName.value].logos.medium?.url
        : undefined,
    );

    /** @deprecated */
    const nurseNavItems = computed<NavItem[]>(() => [
      { label: 'Wallet', icon: 'wallet', to: walletRoute() },
      ...(store.state.auth.user?.passwordCreated
        ? [{ label: 'Profile', icon: 'user', to: profileRoute() }]
        : []),
      ...(isUserApproved.value && flagOn(FeatureFlag.NURSE_ORDER_KIT)
        ? [{ label: 'Order kit', icon: 'shopping-basket', to: orderKitRoute() }]
        : []),
      ...(store.state.auth.user?.passwordCreated
        ? [{ label: 'Work Settings', icon: 'cog', to: workSettingsRoute() }]
        : []),
      { label: 'Help', icon: 'question-circle', click: () => showIntercomMessenger() },
    ]);

    /** @deprecated */
    const officeNavItems = computed<NavItem[]>(() => {
      const items: NavItem[] = [];
      if (isUserApproved.value) {
        if (flagOn(FeatureFlag.OFFICE_VISITS_PAGE)) {
          items.push({ label: 'Visits', icon: 'briefcase-medical', to: visitsPageRoute() });
        }

        items.push(
          { label: 'Orders', icon: 'calendar-day', to: officeOrdersRoute() },
          { label: 'Team', icon: 'users', to: manageTeamRoute() },
          { label: 'Reports', icon: 'chart-area', to: reportsRoute() },
        );
      }

      items.push({ label: 'Your Settings', icon: 'cog', to: userSettingsRoute() });

      if (isUserApproved.value) {
        items.push({ label: 'Office Settings', icon: 'cog', to: officeSettingsRoute() });
      }

      return items;
    });

    const authActions = computed<NavigatorAuthAction[]>(() => {
      const actions: NavigatorAuthAction[] = [];

      if (isNurse.value && isUserApproved.value) {
        actions.push(
          {
            label: 'Profile',
            icon: 'user',
            to: profileRoute(),
          },
          {
            label: 'Wallet',
            icon: 'wallet',
            to: walletRoute(),
          },
          {
            label: 'Help',
            icon: 'question-circle',
            action: () => showIntercomMessenger(),
          },
        );
      }

      return actions;
    });

    const routeOptions = computed<NavigatorRouteOption[]>(() => {
      const options: NavigatorRouteOption[] = [];

      if (isNurse.value) {
        options.push({
          label: 'Your jobs',
          icon: 'calendar-day',
          to: portalJobsRoute(),
        });
        if (store.state.auth.user?.passwordCreated) {
          options.push({
            label: 'Work settings',
            icon: 'cog',
            to: workSettingsRoute(),
          });
        }
      } if (isOfficeAdmin.value) {
        // options.push(...officeRouteOptions.value);
      }

      return options;
    });

    const navItemsForLoggedInUser = computed<NavItem[]>(() => {
      if (isNurse.value) {
        return nurseNavItems.value;
      } if (isOfficeAdmin.value) {
        return officeNavItems.value;
      }

      return [];
    });

    const userOffice = computed<Office | null>(() =>
      store.state.office.office,
    );

    const isPortalUser = computed<boolean>(() =>
      isNurse.value
      || (
        isOfficeAdmin.value
        && userOffice.value?.type === OfficeType.STAFFING
      ),
    );

    const portalChildRoutes = getPortalChildRoutes();
    const isPortalRoute = computed<boolean>(() =>
      portalChildRoutes.some((routeConfig) =>
        !!routeConfig.name
        && !!route.value.name
        && route.value.name === routeConfig.name,
      ),
    );

    const handleUserLoggedOut = () => {
      trackEvent(TrackingEvent.LOGOUT);
    };

    const appClassNames = computed<string[]>(() => {
      const classNames: string[] = [];

      if (!isUserApproved.value) {
        classNames.push('App--incomplete');
      }

      return classNames;
    });

    return {
      Role,
      appClassNames,
      contentElement,
      disablePortal,
      geoRoles,
      handleUserLoggedOut,
      isLoadingBlocking,
      isLoadingNonBlocking,
      isNurse,
      isOfficeAdmin,
      isPortalRoute,
      isPortalUser,
      isUserApproved,
      loginRoute,
      navBarClassNames,
      navBarVisible,
      navItemsForLoggedInUser,
      routeOptions,
      authActions,
      partnerLogoUrl,
      partnerName,
      showIntercomMessenger,
    };
  },
});
