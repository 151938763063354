import {
  DbRef,
  ElementTimestamp,
  EncryptedUser,
  LocationUser,
  OfficeEmailInvitation,
  OfficeInvitationStatus,
  PlaceAccount,
  UserStatus,
} from '@caresend/types';
import { dbGroupSet, dbUpdate, getStore, toastSuccess } from '@caresend/ui-components';
import { getOfficePath, getUserPath } from '@caresend/utils';

import { approveUserRequest } from '@/database/firebase/API';
import { encodeFirebaseRef } from '@/database/firebase/methods';
import { stringToBoolean } from '@/functions/methods';
import { trackDebugEvent } from '@/functions/tracking/tracking';

export const userStatusToInterviewScheduled = (
  userID: string,
  interviewUri: string,
): void => {
  // TODO: Avoid typecast. Add interview to user type or remove this
  // functionality.
  dbUpdate<Partial<EncryptedUser>>(`${DbRef.USERS}/${userID}`, {
    status: UserStatus.INTERVIEW_SCHEDULED,
    interview: { uri: interviewUri },
  } as Partial<EncryptedUser>);
};

export const editUserLocation = (newLocation: Partial<LocationUser>): void => {
  const store = getStore();

  const currentLocation = store.state.auth.user?.location;
  const userID = store.state.auth.user?.id;
  dbUpdate<Partial<LocationUser>>(`${DbRef.USERS}/${userID}/location`, {
    ...currentLocation,
    ...newLocation,
  });
};

export const isOfficeOnUser = (officeID: string, user: EncryptedUser): boolean =>
  user.offices?.some((office) => office.id === officeID) ?? false;

export const addSelfToOffice = async (
  officeID: string,
  user: EncryptedUser,
  approveAccount?: string | undefined,
  toast = true,
  /** Temporary - remove this once we solidify the password creation process */
  debugData: any = {},
): Promise<void> => {
  const timestamp = Date.now();
  const { id: userID, info, role } = user;
  const { email } = info;

  let offices = user.offices ?? [];
  const officeAlreadyListed = isOfficeOnUser(officeID, user);
  await trackDebugEvent('isOfficeOnUser', {
    ...debugData,
    officeAlreadyListed,
  });
  if (!officeAlreadyListed) {
    offices = [
      ...offices ?? [],
      { timestamp, id: officeID },
    ];
  }

  const officePath = getOfficePath(officeID);
  type UpdateType = ElementTimestamp | ElementTimestamp[] | OfficeEmailInvitation
  const updates: Record<string, UpdateType> = {
    [`${officePath}/${role}s/${userID}`]: { timestamp, id: userID },
    [getUserPath(userID, 'offices')]: offices,
  };

  /** Update office invitation status */
  await trackDebugEvent('update office invitation status', {
    ...debugData,
    updates,
  });
  const emailEncoded = encodeFirebaseRef(email);
  if (email) {
    updates[`${officePath}/invitations/${emailEncoded}`] = {
      email,
      status: OfficeInvitationStatus.ACCEPTED,
      timestamp: Date.now(),
      userID,
    } as OfficeEmailInvitation;
  }

  await trackDebugEvent('dbGroupSet addSelfToOffice updates', {
    ...debugData,
    updates,
  });
  await dbGroupSet<UpdateType>(updates);

  if (stringToBoolean(approveAccount)) {
    await trackDebugEvent('approveUserRequest', debugData);
    await approveUserRequest({ userID });
  }

  if (toast) toastSuccess('Successfully joined organization!');
};

export const addPlaceAccountToUser = (userID: string, placeAccount: PlaceAccount) => {
  if (!userID || !placeAccount) return;

  const { id } = placeAccount;
  const userPlaceAccountPath = `${DbRef.USERS}/${userID}/placeAccounts/${id}`;
  const placeAccountPath = `${DbRef.PLACE_ACCOUNTS}/${id}`;

  const updates: Record<string, ElementTimestamp | PlaceAccount> = {
    [userPlaceAccountPath]: { id, timestamp: Date.now() },
    [placeAccountPath]: placeAccount,
  };

  return dbGroupSet<ElementTimestamp | PlaceAccount>(updates);
};

export const deleteUserPlaceAccount = (userID: string, placeAccount: PlaceAccount) => {
  if (!userID || !placeAccount) return;

  const { id } = placeAccount;
  const userPlaceAccountPath = `${DbRef.USERS}/${userID}/placeAccounts/${id}`;

  const updates: Record<string, null> = {
    [userPlaceAccountPath]: null,
  };

  return dbGroupSet<null>(updates);
};
