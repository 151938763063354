import '@/utils/initDevtools';
import '@/unregisterServiceWorker';
import 'buefy/dist/buefy.css';
import 'focus-visible';
import '@stripe/stripe-js';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import '@/style/index.sass';
import {
  FormHeader,
  getRouter,
  getStore,
  initBuefy,
  initFirebase,
  initSegment,
} from '@caresend/ui-components';
import {
  faBriefcaseMedical,
  faChartArea,
  faCommentAlt,
  faDollarSign,
  faFilter,
  faHouseUser,
  faLongArrowAltLeft,
  faMapMarkedAlt,
  faMobile,
  faPaw,
  faPhoneAlt,
  faShoppingBasket,
  faSortDown,
  faSortUp,
  faSyringe,
  faUsers,
  faVial,
  faVideo,
} from '@fortawesome/sharp-regular-svg-icons';
import * as dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { PiniaVuePlugin, createPinia } from 'pinia';
import Vue from 'vue';
import VueCalendly from 'vue-calendly';
import VueMobileDetection from 'vue-mobile-detection';

import App from '@/App.vue';
import DateTimeView from '@/components/layout/date/DateTimeView.vue';
import ImagePane from '@/components/layout/image/ImagePane.vue';
import Notes from '@/components/layout/rows/Notes.vue';
import PageHeader from '@/components/layout/text/PageHeader.vue';
import { SEGMENT_TOKEN } from '@/database/credentials';
import { initializeSentry } from '@/functions/tracking';
import { initRouter } from '@/router';
import { initStore } from '@/store';

dayjs.extend(advancedFormat);
dayjs.extend(calendar);
dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

initFirebase();
initSegment(SEGMENT_TOKEN);
initializeSentry();
initStore();

/* eslint-disable vue/component-definition-name-casing */
/**
 * TODO: Remove all global components.
 */
Vue.component('image-pane', ImagePane);
Vue.component('date-view', DateTimeView);
Vue.component('page-header', PageHeader);
Vue.component('form-header', FormHeader);
Vue.component('notes', Notes);

Vue.use(VueMobileDetection);

const faSharpRegularAdditionalIcons = [
  faBriefcaseMedical,
  faChartArea,
  faCommentAlt,
  faDollarSign,
  faFilter,
  faHouseUser,
  faLongArrowAltLeft,
  faMapMarkedAlt,
  faMobile,
  faPaw,
  faPhoneAlt,
  faShoppingBasket,
  faSortDown,
  faSortUp,
  faSyringe,
  faUsers,
  faVial,
  faVideo,
];
initBuefy(Vue, faSharpRegularAdditionalIcons);
Vue.use(VueCalendly);

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);

const initApp = async () => {
  await getStore().dispatch('app/initApp');
  const pinia = createPinia();

  initRouter();

  new Vue({
    name: 'Pro',
    router: getRouter(),
    store: getStore(),
    pinia,
    render: (h) => h(App),
  }).$mount('#app');
};

initApp();
