import {
  ByID,
  DocumentObject,
  DraftWaypointSupplyStrategy,
  EncryptedOrder,
  EncryptedUser,
  IdObj,
  Order,
  PhoneNumber,
  Procedure,
  ProcedureData,
  ProcedureDocumentType,
  ProcedureStatus,
  Sample,
  SetProcedureStatusesRequest,
  SupplyItemWithSupplyID,
} from '@caresend/types';
import {
  AnyGetters,
  ExtendedCustomModule,
  Getters,
  ProceduresModule,
  ProceduresState,
  getProcedureDataRequest,
  initProceduresModule,
  setProcedureDataInStore,
  toastErrorAndReport,
} from '@caresend/ui-components';
import {
  arrayToObj,
  errorSuffix,
  formatFullName,
  formatPhoneNumber,
  hasStatus,
  isNullish,
  nullishFilter,
} from '@caresend/utils';
import update from 'immutability-helper';

import { setProcedureStatusesRequest } from '@/database/firebase/API';
import type { CustomStore, RootState } from '@/store/model';
import { ExtendedBookingsGetters } from '@/store/modules/bookings';
import { procedureFlowTypeIsLabDraw } from '@/store/modules/itineraryFlow/helpers';
import { ExtendedOrdersGetters } from '@/store/modules/orders';
import { sampleExists } from '@/store/modules/procedures/helper';
import {
  ExtraProceduresActions,
  SetOrUnsetProcedureKit,
  SetOrUnsetProcedureSpecimenBag,
  SetProcedureInsulatedBagInStore,
  SetProcedureSpecimenBagSupplyItem,
  SetSampleAmberBagInStore,
  SetSampleStatus,
  SetSampleSupplyItem,
  UnsetSampleAmberBagInStore,
} from '@/store/modules/procedures/model';
import { SetOrUnset } from '@/store/modules/supplies/model';
import { ExtendedUsersGetters } from '@/store/modules/users';

// TODO: `Procedure.specimenBag` is incorrectly typed. We save it to the
// db before it has `id`, so `id` should be optional. Using a mock type for
// now.
type RealSpecimenBagType = Omit<SupplyItemWithSupplyID, 'id'> & { id?: string };

type S = ProceduresState;

const extraProceduresMutations = {
  'procedures/SET_PROCEDURE': (state: S, newProcedure: Procedure) => {
    state.procedures = {
      ...state.procedures,
      [newProcedure.id]: newProcedure,
    };
  },

  'procedures/SET_PROCEDURE_SAMPLE_STATUS': (state: S, {
    procedureID,
    sampleID,
    status,
    collectionFailure,
    dropOffFailure,
  }: SetSampleStatus) => {
    if (!sampleExists(state, procedureID, sampleID)) return;

    state.procedures = update(state.procedures, {
      [procedureID]: {
        samples: {
          [sampleID]: {
            status: { $set: status },
            collectionFailure: { $set: collectionFailure },
            dropOffFailure: { $set: dropOffFailure },
          },
        },
      },
    });
  },

  'procedures/SET_PROCEDURE_SAMPLE_SUPPLY_ITEM': (state: S, {
    procedureID,
    sampleID,
    supplyItemID,
  }: SetSampleSupplyItem) => {
    if (!sampleExists(state, procedureID, sampleID)) return;

    state.procedures = update(state.procedures, {
      [procedureID]: {
        samples: {
          [sampleID]: {
            supplyItemID: { $set: supplyItemID },
          },
        },
      },
    });
  },

  'procedures/SET_PROCEDURE_SAMPLE_AMBER_BAG': (state: S, {
    procedureID,
    sampleID,
    amberBag,
  }: SetSampleAmberBagInStore) => {
    const currentProcedure = state.procedures[procedureID];
    if (!currentProcedure || !amberBag) return;
    const currentSample = state.procedures[procedureID]?.samples?.[sampleID];
    if (!currentSample) return;
    state.procedures = {
      ...state.procedures,
      [procedureID]: {
        ...currentProcedure,
        amberBags: {
          ...currentProcedure.amberBags,
          [amberBag.id]: amberBag,
        },
      },
    };
  },

  'procedures/UNSET_PROCEDURE_SAMPLE_AMBER_BAG': (state: S, {
    procedureID,
    sampleID,
    amberBagID,
  }: UnsetSampleAmberBagInStore) => {
    const currentProcedure = state.procedures[procedureID];
    if (!currentProcedure) return;
    const currentSample = state.procedures[procedureID]?.samples?.[sampleID];
    if (!currentSample) return;
    const currentAmberBags = currentProcedure.amberBags;
    delete currentAmberBags?.[amberBagID];
    state.procedures = {
      ...state.procedures,
      [procedureID]: {
        ...currentProcedure,
        amberBags: currentAmberBags,
      },
    };
  },

  'procedures/SET_PROCEDURE_INSULATED_BAG': (state: S, {
    procedureID,
    sampleID,
    insulatedBagSupplyItemID,
    insulatedBagSupplyID,
    previousSupplyItem,
  }: SetProcedureInsulatedBagInStore) => {
    const currentProcedure = state.procedures[procedureID];
    if (!currentProcedure || !insulatedBagSupplyItemID) return;
    const currentSample = state.procedures[procedureID]?.samples?.[sampleID];
    if (!currentSample) return;
    const currentInsulatedBagSupplyItems = currentProcedure.insulatedBag?.relatedSupplyItems;
    state.procedures = {
      ...state.procedures,
      [procedureID]: {
        ...currentProcedure,
        insulatedBag: {
          id: insulatedBagSupplyItemID,
          supplyID: insulatedBagSupplyID,
          relatedSupplyItems: {
            ...currentInsulatedBagSupplyItems,
            [previousSupplyItem.supplyItem.id]: previousSupplyItem.supplyItem,
          },
        },
      },
    };
  },

  'procedures/SET_PROCEDURE_SPECIMEN_BAG': (
    state: S,
    { procedureID, newSpecimenBag }: {
      procedureID: string;
      newSpecimenBag: SupplyItemWithSupplyID;
    },
  ) => {
    state.procedures = update(state.procedures, {
      [procedureID]: {
        specimenBag: { $set: newSpecimenBag },
      },
    });
  },

  'procedures/SET_PROCEDURE_SPECIMEN_BAG_ID': (state: S, {
    instruction,
    procedureID,
    specimenBagSupplyItemID,
    specimenBagSupplyID,
  }: SetOrUnsetProcedureSpecimenBag) => {
    const currentProcedure = state.procedures[procedureID];
    if (!currentProcedure) {
      toastErrorAndReport('Missing procedure when setting specimen bag ID.');
      return;
    }
    let newSpecimenBag = update(currentProcedure.specimenBag ?? {} as RealSpecimenBagType, {
      supplyID: { $set: specimenBagSupplyID },
    });
    if (instruction === SetOrUnset.SET) {
      newSpecimenBag = update(newSpecimenBag, {
        id: { $set: specimenBagSupplyItemID },
      });
    } else {
      newSpecimenBag = update(newSpecimenBag, { $unset: ['id'] });
    }

    state.procedures = update(state.procedures, {
      [procedureID]: {
        specimenBag: { $set: newSpecimenBag as SupplyItemWithSupplyID },
      },
    });
  },

  'procedures/SET_PROCEDURE_KIT_ID': (state: S, {
    instruction,
    procedureID,
    procedureKitSupplyItemID,
    procedureKitSupplyID,
  }: SetOrUnsetProcedureKit) => {
    const currentProcedure = state.procedures[procedureID];
    if (!currentProcedure) {
      toastErrorAndReport('Missing procedure when setting procedure kit ID.');
      return;
    }

    const set = instruction === SetOrUnset.SET;

    let newProcedure: Procedure;

    if (set) {
      const newKitSupplyItem: SupplyItemWithSupplyID = {
        id: procedureKitSupplyItemID,
        supplyID: procedureKitSupplyID,
      };
      newProcedure = update(currentProcedure, {
        kitSupplyItem: { $set: newKitSupplyItem },
      });
    } else {
      newProcedure = update(currentProcedure, { $unset: ['kitSupplyItem'] });
    }

    state.procedures = update(state.procedures, {
      [procedureID]: { $set: newProcedure },
    });
  },

  'procedures/SET_PROCEDURE_SPECIMEN_BAG_SUPPLY_ITEM': (state: S, {
    procedureID,
    specimenBagSupplyItem,
  }: SetProcedureSpecimenBagSupplyItem) => {
    const currentProcedure = state.procedures[procedureID];
    if (!currentProcedure || !specimenBagSupplyItem) return;

    /**
     * Due to the charting flow, samples are scanned prior to the specimen bag.
     * This means that the specimen bag supply item may not exist in the procedure
     */
    state.procedures = update(state.procedures, {
      [procedureID]: { $set: update(currentProcedure, {
        specimenBag: { $set: update(currentProcedure.specimenBag ?? {} as SupplyItemWithSupplyID, {
          relatedSupplyItems: { $set: update(currentProcedure.specimenBag?.relatedSupplyItems ?? {}, {
            [specimenBagSupplyItem.id]: { $set: specimenBagSupplyItem },
          }) },
        }) },
      }) },
    });
  },

  'procedures/SET_PROCEDURES': (state: S, newProcedures: ByID<Procedure>) => {
    state.procedures = {
      ...state.procedures,
      ...newProcedures,
    };
  },

  'procedures/UPDATE_PROCEDURE': (
    state: S,
    procedureUpdate: Partial<Procedure> & IdObj,
  ) => {
    const procedure = state.procedures[procedureUpdate.id];
    if (!procedure) {
      toastErrorAndReport(`Missing procedure ${procedureUpdate.id}`);
      return;
    }
    const newProcedure: Procedure = {
      ...procedure,
      ...procedureUpdate,
    };
    state.procedures = {
      ...state.procedures,
      [newProcedure.id]: newProcedure,
    };
  },

  'procedures/UPDATE_PROCEDURE_SAMPLE': (state: S, {
    procedureID, sample,
  }: { procedureID: string; sample: Sample }) => {
    const procedure = state.procedures[procedureID];
    if (!procedure) {
      toastErrorAndReport(`Missing procedure ${procedureID} when updating sample`);
      return;
    }

    state.procedures = update(state.procedures, {
      [procedureID]: {
        samples: {
          [sample.id]: { $set: sample },
        },
      },
    });
  },

  'procedures/RESET_PROCEDURES': (state: S) => {
    state.procedures = {};
  },
};

const extraProceduresActions: ExtraProceduresActions = {
  'procedures/setProcedureStatuses': async ({ commit, dispatch, state }, params: SetProcedureStatusesRequest) => {
    const {
      officeType,
      procedureIDs,
      status,
    } = params;
    const procedureCanceled = await setProcedureStatusesRequest({
      procedureIDs,
      status,
      officeType,
    });
    const newProceduresArray = (await Promise.all(procedureIDs.map(async (procedureID) => {
      const procedure = state.procedures[procedureID];
      if (!procedure) {
        return;
      }

      if (hasStatus(status, ProcedureStatus.CANCELED)) {
        const waypointActionIDs = Object.keys(procedure.waypointActions ?? {});
        await dispatch('waypoint/maybeCancelWaypointActions', { waypointActionIDs });
      }

      return {
        ...procedure,
        status,
        procedureCanceled,
      };
    }))).filter(nullishFilter);

    const newProcedures = arrayToObj(newProceduresArray, 'id');
    commit('procedures/SET_PROCEDURES', newProcedures);
  },

  'procedures/fetchProcedureData': async function (this: CustomStore, { commit }, procedureID) {
    try {
      const procedureData: ProcedureData | undefined = await getProcedureDataRequest({
        procedureID,
        getOrderDataInclusions: {
          includeBookingsDataInOrderData: true,
          includeOrderDataInProcedureData: true,
          includePatientDataInBookingData: true,
          includePlaceGroupsDataInProcedureData: true,
          includePrescriberDataInOrderData: true,
          includeContactDataInProcedureData: true,
          includeSupplyItemsDataInProcedureData: true,
        },
      });
      if (isNullish(procedureData)) throw Error(`Unable to load data for procedure ${procedureID}.`);
      await setProcedureDataInStore(commit, [procedureData], this);
    } catch (error) {
      toastErrorAndReport(`There was a problem loading your procedure data. ${errorSuffix}`);
    }
  },
};

const extraProceduresGetters = {
  'procedures/getDocuments': (state: S) => (
    procedureID: string,
    documentType: ProcedureDocumentType = ProcedureDocumentType.REQUISITION_FORM,
  ): DocumentObject<ProcedureDocumentType>[] | undefined => {
    const procedure = state.procedures[procedureID];
    if (!procedure) return;

    const documents = procedure?.documents;
    if (!documents) return;

    return documents.filter(({ type }) => type === documentType);
  },

  'procedures/getPrimaryContactName': (state: S, getters: AnyGetters, rootState: RootState) => (
    procedureID: string,
  ): string | undefined => {
    const procedure = state.procedures[procedureID];
    if (!procedure || !procedure.contact) return;
    if (procedure.contact.userID) {
      const user = rootState.users.users?.[procedure.contact.userID];
      if (user) return formatFullName(user);
    }

    return procedure.contact?.name;
  },

  /** Returns an array of the primary contact's phone number and/or email */
  'procedures/getPrimaryContactDetails': (state: S, getters: AnyGetters, rootState: RootState) => (
    procedureID: string,
  ): string[] => {
    const procedure = state.procedures[procedureID];
    if (!procedure || !procedure.contact) return [];
    const details: string[] = [];

    const user = rootState.users.users?.[procedure.contact.userID ?? ''];

    const phone = user?.info?.phone ?? procedure.contact?.phone;
    const formattedPhone = formatPhoneNumber(phone);
    details.push(formattedPhone ?? '');

    if (user?.info?.email) {
      details.push(user?.info?.email);
    }

    return details.filter((detail) => detail !== '');
  },

  'procedures/getPrimaryContactPhoneNumber': (state: S, getters: AnyGetters, rootState: RootState) => (
    waypointID: string,
  ): PhoneNumber | undefined => {
    const waypoint = rootState.waypoint.waypoints[waypointID];
    if (!waypoint || !waypoint.waypointActions) return;

    const firstWaypointActionIDObj = waypoint.waypointActions[0];
    if (!firstWaypointActionIDObj) return;

    const firstWaypointActionID = firstWaypointActionIDObj.id;
    const firstWaypointAction = rootState.waypoint.waypointActions[firstWaypointActionID];
    if (!firstWaypointAction || !firstWaypointAction.procedures) return;

    const procedureKeys = Object.keys(firstWaypointAction.procedures);
    if (!procedureKeys.length) return;

    const firstProcedureID = procedureKeys[0];
    if (!firstProcedureID) return;

    const firstProcedure = state.procedures[firstProcedureID];
    const contact = firstProcedure?.contact;
    if (!contact) return;

    if (contact.custom) {
      if (!contact.phone) console.error('This is a custom primary contact but no phone number was found.');
      return contact.phone;
    }
    const { userID } = contact;
    const user = rootState.users.users?.[userID ?? ''];
    if (!user) {
      console.error('The primary contact is a patient but they were not found.');
      return;
    }
    return user.info?.phone;
  },

  'procedures/getIsLabDrawType': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (procedureID: string) =>
    procedureFlowTypeIsLabDraw(procedureID, rootState),

  'procedures/getProcedureBooking': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (procedureID: string) => {
    const bookingID = state.procedures[procedureID]?.bookingID;
    if (!bookingID) return;
    return rootState.bookings.bookings?.[bookingID];
  },

  'procedures/getProceduresOnBookingById': (state: S) => (bookingID: string): string[] => (
    Object.values(state.procedures)
      .filter((procedure) => procedure.bookingID === bookingID)
      .map((procedure) => procedure.id)
  ),

  'procedures/getProcedurePatient': (state: S, getters: AnyGetters, rootState: RootState) => (
    procedureID: string,
  ): EncryptedUser | undefined => {
    const patientID = extraProceduresGetters[
      'procedures/getProcedurePatientID'
    ](state, getters, rootState)(procedureID);
    return patientID ? rootState.users.users[patientID] : undefined;
  },

  'procedures/getProcedurePatientID': (state: S, getters: AnyGetters, rootState: RootState) => (
    procedureID: string,
  ): string | undefined => {
    const booking = extraProceduresGetters[
      'procedures/getProcedureBooking'
    ](state, getters, rootState)(procedureID);
    return booking?.patientID;
  },

  'procedures/getPatientOnProcedure': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (
    /** Procedure object or ID */
    procedure: Procedure | string,
  ): EncryptedUser | null => {
    const procedureObj = typeof procedure === 'string'
      ? state.procedures?.[procedure]
      : procedure;
    const { bookingID } = procedureObj ?? {};
    if (!bookingID) return null;
    const booking = rootState.bookings.bookings?.[bookingID] ?? null;
    const patientID = booking?.patientID;

    if (!patientID) return null;
    return rootState.users.users?.[patientID] ?? null;
  },

  'procedures/getProcedureOrderID': (
    state: S,
    _getters: AnyGetters,
    _rootState: RootState,
    rootGetters: Getters<ExtendedBookingsGetters>,
  ) => (procedureID: string): string | undefined => {
    const procedure = state.procedures[procedureID];
    const bookingID = procedure?.bookingID;
    if (!bookingID) return;
    const orderID = rootGetters['bookings/getBookingOrderID'](bookingID);
    return orderID;
  },

  'procedures/getProcedureOrder': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
    rootGetters: Getters<ExtendedBookingsGetters>,
  ) => (procedureID: string): Order | EncryptedOrder| undefined => {
    const orderID = extraProceduresGetters['procedures/getProcedureOrderID'](
      state,
      getters,
      rootState,
      rootGetters,
    )(procedureID);
    if (!orderID) return;
    const order = rootState.orders.orders?.[orderID];
    return order;
  },

  'procedures/getProcedureOfficeID': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
    rootGetters: Getters<ExtendedBookingsGetters>,
  ) => (procedureID: string) => {
    const orderID = extraProceduresGetters[
      'procedures/getProcedureOrderID'
    ](state, getters, rootState, rootGetters)(procedureID);
    if (!orderID) return;
    const officeID = rootState.orders.orders?.[orderID]?.officeID;
    return officeID;
  },

  'procedures/getProcedurePrescriberID': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
    rootGetters: Getters<ExtendedBookingsGetters>,
  ) => (procedureID: string) => {
    const orderID = extraProceduresGetters[
      'procedures/getProcedureOrderID'
    ](state, getters, rootState, rootGetters)(procedureID);
    if (!orderID) return;
    const prescriberID = rootState.orders.orders?.[orderID]?.prescriberID;
    return prescriberID;
  },

  'procedures/getSamplesByProcedureID': (state: S) => (
    procedureID: string,
  ): Sample[] => {
    const procedure = state.procedures[procedureID];
    return Object.values(procedure?.samples ?? {});
  },

  'procedures/getSamplesRequiringCentrifugation': (state: S) => (
    procedureID: string,
  ): Sample[] => extraProceduresGetters[
    'procedures/getSamplesByProcedureID'
  ](state)(procedureID).filter(({ processing }) => !!processing.centrifuge),

  'procedures/getSupplyStrategy': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (procedureID: string): DraftWaypointSupplyStrategy | undefined => {
    const procedure = state.procedures[procedureID];
    if (!procedure) return;
    const draftWaypoint = rootState.waypoint.draftWaypoints[procedure?.draftWaypointID ?? ''];
    return draftWaypoint?.supplyStrategy;
  },

  'procedures/hasMatchingPlaceGroup': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (procedure: Procedure): boolean => {
    const officePlaceGroupID = rootState.office.office?.placeGroupID;
    return !!procedure.dropOffPlaceGroups?.[officePlaceGroupID ?? ''];
  },
};

const proceduresModuleExtension = {
  mutations: extraProceduresMutations,
  actions: extraProceduresActions,
  getters: extraProceduresGetters,
};

export const proceduresModule: ExtendedCustomModule<
  ProceduresModule<RootState, ExtendedUsersGetters & ExtendedOrdersGetters>,
  typeof proceduresModuleExtension
> = initProceduresModule(proceduresModuleExtension);

export type ExtendedProceduresModule = typeof proceduresModule;
export type ExtendedProceduresMutations = ExtendedProceduresModule['mutations'];
export type ExtendedProceduresActions = ExtendedProceduresModule['actions'];
export type ExtendedProceduresGetters = ExtendedProceduresModule['getters'];
