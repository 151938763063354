import { LocationObject, TrackingEvent } from '@caresend/types';
import { analytics, getRouter, getStore } from '@caresend/ui-components';
import { getUnknownErrorMessage } from '@caresend/utils';

import { getCurrentLocation } from '@/functions/getCurrentLocation';

export const trackEvent = async (event: TrackingEvent, data?: any, addGeo = false) => {
  const router = getRouter();
  const { state } = getStore();

  const routeName = router.currentRoute.name ?? '';
  let geo: LocationObject | undefined;
  let geoError: string | undefined;

  if (addGeo) {
    try {
      geo = await getCurrentLocation();
    } catch (error) {
      if (error instanceof GeolocationPositionError) {
        const codes = {
          [error.PERMISSION_DENIED]: 'PERMISSION_DENIED',
          [error.POSITION_UNAVAILABLE]: 'POSITION_UNAVAILABLE',
          [error.TIMEOUT]: 'TIMEOUT',
        };
        geoError = codes[error.code as keyof typeof codes];
      } else {
        geoError = getUnknownErrorMessage(error);
      }
    }
  }

  const { user } = state.auth;
  const userID = user ? user.id : '';
  const email = user ? user.info.email : '';
  const offices = user?.offices;
  const medicalTitle = user?.professionalInfo?.title ?? '';
  const timestamp = Date.now();
  analytics.track(event, {
    email,
    geo,
    geoError,
    medicalTitle,
    offices,
    route: routeName,
    timestamp,
    userID,
    ...data,
  });
};

export const trackDebugEvent = async (name: string, data?: any) => {
  await trackEvent(TrackingEvent.DEBUG, { name, ...data });
};
